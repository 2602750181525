.rangeInput {
  width: 620px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__scale {
    height: 15px;
    position: absolute;
    border-left: 1px solid $RAGrayColor;
    top: 6px;
    left: 10px;
    right: 9px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    div:not(:last-child) {
      //width: 100%;
      flex-grow: 1;
      border-right: 1px solid $RAGrayColor;
    }

    &:before {
      content: '';
      position: absolute;
      top: 7px;
      left: 0;
      width: 100%;
      height: 1px;
      background: $RAGrayColor;
    }
  }

  &__input {
    z-index: 100;
    width: 100%;
    outline: none;
    -webkit-appearance: none;
    background: transparent;

    &::-webkit-slider-runnable-track {
      height: 22px;
      -webkit-appearance: none;
    }

    &::-webkit-slider-thumb {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      -webkit-appearance: none;
      z-index: 100;
    }

    //&.latuda::-webkit-slider-thumb {
    //  background-color: $RALightBlueColor;
    //}
    //
    //&.vellofent::-webkit-slider-thumb {
    //  background-color: $RABlueColor;
    //}
    //
    //&.trittico::-webkit-slider-thumb {
    //  background-color: $RAYellowColor;
    //}
    //
    //&.macmiror::-webkit-slider-thumb {
    //  background-color: $RAPinkColor;
    //}

  }

  &__dataList {
    margin-top: 3px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: auto;
    overflow: hidden;

    option {
      width: 22px;
      text-align: center;
      @include font($RAGrayColor, 400, 14px, 16px)
    }
  }
}