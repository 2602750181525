.auth, .reset {
  background-color: $RAGreenColor;
  padding-bottom: 70px;

  &__title {
    display: flex;
    align-items: center;

    h1 {
      margin: 50px 80px;
      @include font($RAWhiteColor, 400, 35px, 42px);
      text-align: center;
    }

    &-line {
      height: 2px;
      background-color: $RAWhiteColor;
      flex-grow: 1;
      //min-width: 350px;
    }
  }

  &__content {
    margin-top: 50px;

    &-buttonArea {
      margin-top: 50px;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }
  }

  &__buttonArea {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    span {
      @include font($RAWhiteColor, 400, 20px, 24px);
      margin: 0 10px;
    }
  }
}