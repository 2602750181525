.supervisorDash {
  &__sortingArea {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    label {
      min-width: 320px;
      display: inline-block;
      margin-left: 20px;
      margin-top: 20px;
    }

    label:last-child {
      select {
        margin-left: 25px;
      }
    }

    input {
      margin-top: 20px;
    }
  }

  &__cardsArea {
    margin-top: 25px;
    padding-right: 10px;
    min-height: 17px;
    max-height: calc(100vh - 325px);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: auto;
    @include customScroll();
  }
}