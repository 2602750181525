.modal {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, .1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  @include flexAlignItem(center, center);
  overflow: hidden;

  &__wrapper {
    padding: 50px;
    min-width: 310px;
    background-color: $RAWhiteColor;
    border-radius: 3px;
    border: 1px solid $RAGrayColor;
    box-shadow: 0 4px 4px rgba(0, 0, 0, .25);

    &-title {
      text-align: center;
      @include font($RAGrayColor, 900, 16px, 19px);
    }

    &-content {
        margin: 60px 0;
    }

    &-buttonArea {
      display: flex;
      justify-content: space-around;
      align-items: center;
      button {
        @include font($RAGrayColor, 900, 16px, 19px);
      }
    }
  }
}