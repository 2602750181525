.sharedInput {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  align-items: center;

  label {
    @include font($RAGrayColor, 700, 16px, 19px);
    margin-right: 5px;
  }

  & > input {
    width: 188px;
    height: 30px;
    padding-left: 15px;
    border: 1px solid $RAGrayColor;
    outline: none;
    color: $RAGrayColor;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    border-radius: 3px;
  }
}