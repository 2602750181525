.nc1, .nc2, .subInfo {
  height: calc(100vh - 114px - 173px - 20px);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  &__content {
    flex-grow: 1;

    &-additionalInfo {
      list-style-type: disc;
      list-style-position: inside;
      li {
        margin-top: 10px;
        a {
          margin-left: 10px;
        }
      }
    }

    &-dataArea {
      height: 500px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 auto;
      width: 75%;
      min-width: 300px;
      max-width: 900px;

      .formControl {
        height: 101px;

        p {
          margin: 0;
        }

        label {
          margin: 20px 0 10px 0;
        }

        input {
          width: 100%;
          max-width: 900px;
        }
      }


      //h1 {
      //  @include font($RAGrayColor, 700, 16px, 19px);
      //}

      h2 {
        margin-top: 10px;
        @include font($RAGrayColor, 400, 20px, 25px);
      }

      h3 {
        @include font($RAGrayColor, 700, 16px, 19px);
      }

      h5 {
        margin-top: 40px;
        @include font($RAGrayColor, 400, 14px, 17px);
      }

      h6 {
        margin-top: 10px;
        @include font($RAGrayColor, 400, 12px, 14px);
      }
    }

    &-buttonArea {
      display: flex;
      justify-content: space-between;
    }
  }
}


.nc2, .nc3 {
  &__content {
    &-dataArea {
      h3 {
        margin-top: 10px;
      }

      h6 {
        width: 60%;
        margin: 10px 0 10px 0;
      }
    }
  }
}