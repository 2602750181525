.doctorDash {

  &__sortingArea {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__cardsArea {
    margin-top: 25px;
    padding-right: 10px;
    max-height: calc(100vh - 325px);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: auto;
    @include customScroll();
  }
}