.dashTitle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 80px;

  &__textArea {
    margin: 0 144px;
    text-align: center;
  }

  &__mainTitle {
    @include font($RAGrayColor, 400, 35px, 42px);
  }

  &__subTitle {
    margin-top: 15px;
    @include font($RAGrayColor, 400, 20px, 24px);
  }

  &__decorLine {
    flex-grow: 1;
    height: 2px;
    background-color: $RAGrayColor;
    margin-top: 20px;
  }
}