.newCaseMenu {
  margin-top: 20px;

  .container {
    padding: 20px 0;

    nav {
      padding-bottom: 15px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-around;
      align-items: center;
      border-bottom-width: 1px;
      border-bottom-style: solid;
    }
  }

  &__link, &__fLink {
    text-decoration: none;
    color: inherit;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      top: 26px;
      left: calc(50% - 5px);
      border-radius: 50%;
    }
  }

  &__fLink {
    cursor: default;
  }

  &__link:hover:after,
  &__activeLink:after {
    width: 20px;
    height: 20px;
    top: 21px;
    left: calc(50% - 10px);
  }
}