.userTable {
  margin-top: 30px;
  width: 100%;
  background-color: $RAWhiteColor;

  tbody {
    margin-top: 20px;
    display: block;
    overflow-y: auto;
    max-height: calc(100vh - 400px);
    @include customScroll()
  }

  td {
    vertical-align: middle;
  }

  thead, tbody tr {
    display: table;
    width: 100%;
  }

  tr {
    td:nth-child(1) {
      min-width: 50px;
    }

    td:nth-child(2) {
      min-width: 100px;
    }

    td:nth-child(3) {
      min-width: 150px;
    }

    td:nth-child(4) {
      min-width: 350px;
    }

    td:nth-child(5) {
      min-width: 100px;
    }
  }

  thead > tr > td:nth-child(5) {
    padding-right: 30px;
  }

  &__titleCell {
    @include flexAlignItems(row, no-wrap, flex-start, center);

    p, img {
      cursor: pointer;
    }

    p {
      @include font($RAGrayColor, 700, 18px, 22px);
      margin-right: 10px;
    }

    &-addButton {
      @include flexAlignItems(column, no-wrap, center, center);
      cursor: pointer;

      p {
        @include font($RAGrayColor, 700, 18px, 22px);
      }
    }
  }

  &__contentRow:nth-child(2n) {
    background-color: #FCFCFC;
  }

  &__contentRow {
    td:last-child {
      div {
        @include flexAlignItem(center, center)
      }
    }
  }

  &__contentCell {
    height: 35px;
    @include flexAlignItem(flex-start, center);

    &-enableEdit {
      cursor: pointer;
    }

    &-enableEdit:not(:last-child) {
      margin-right: 20px;
    }
  }

  &__emptyTableMessage {

    h1 {
      margin-top: 50px;
      text-align: center;
      @include font($RAGrayColor, 700, 25px, 25px);
    }
  }
}