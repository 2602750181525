.App {
  width: 100%;
  height: 100vh;
  background: url("../assets/img/mainBG.png") bottom right no-repeat;
  overflow-y: hidden;

  &__ppLink {
    text-decoration: none;
    color: $RAGrayColor;
    position: fixed;
    bottom: 10px;
    right: 20px;
  }
}

.container {
  width: 80%;
  max-width: 1440px;
  min-width: 350px;
  margin: 0 auto;
}