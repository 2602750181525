.profile {
  &__content {
    margin-top: 50px;

    &-author,
    &-changeButton,
    &-buttonArea {
      width: 50%;
      min-width: 300px;
      max-width: 750px;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &-author {
      margin-bottom: 20px;
      @include font($RAGrayColor, 400, 19px, 20px);

      span {
        margin-right: 20px;
        @include font($RAGrayColor, 700, 16px, 19px);
      }
    }

    &-changeButton {
      button {
        font-weight: 400;
      }
    }

    &-buttonArea {
      margin-top: 50px;
      justify-content: space-around;
    }
  }
}